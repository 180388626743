import './cart.scss';
import { Link } from "react-router-dom";
import { useContext, useState} from 'react';
import { CartContext } from '../../components/context';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Store } from 'react-notifications-component';
import { translateText } from './../article/article'
import axios from 'axios';
const math = require('mathjs');

export default function Cart() {
  const {cartValue, setCartValue} = useContext(CartContext);
  let cartAmount = 0;
  let articleAmount = 0
  let hasShipping =  false;
  let shippingCost = 0;
  const rabatProItem = 0.8;
  const mitgliederRabatFlag = window.sessionStorage.getItem("mitglied");
  const abholungFlag = window.sessionStorage.getItem("abholung");
  const deleteAll = () => {
    setCartValue([])
  }
  const [checkAGB, setCheckedAGB] = useState(false);
  const handleChange = () => {
    setCheckedAGB(!checkAGB);
  };
  const initNewsletterCheck = JSON.parse(window.sessionStorage.getItem("newsletter"));
  const [checkNewsletter, setCheckNewsletter] = useState(initNewsletterCheck);
  const handleChangeNewsletter = () => {
    setCheckNewsletter(!checkNewsletter);
    window.sessionStorage.setItem("newsletter", !checkNewsletter);
  };

  const deleteItem = (currentItem) => {
    const index = cartValue.findIndex(x => x.id === parseInt(currentItem.id)&&((x.size &&x.size===currentItem.size)||!x.size)&&((x.option &&x.option===currentItem.option)||!x.option))
    if (index > -1) {
     const newValue = cartValue.filter((_, i) => i !== index);
     if(cartValue.length===0){
       setCartValue([])
     }else{
       setCartValue([...newValue])
     }
    }
  }

  const addAmount = (currentItem) => {
    const index = cartValue.findIndex(x => x.id === parseInt(currentItem.id)&&((x.size &&x.size===currentItem.size)||!x.size)&&((x.option &&x.option===currentItem.option)||!x.option))
    const newItem = cartValue[index];

    if(newItem.subType1==='Support'){
      newItem.amount = 1;
    }else{
      newItem.amount++
    }
    const newValue = cartValue.filter((_, i) => i !== index);
    newValue.push(newItem)
    setCartValue([...newValue])
  }

  const throwError = (error = 'Irgendetwas lief schief') =>{
    Store.addNotification({
      title: "Upps",
      message: error,
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
      }
    });
  }

  const removeAmount = (currentItem) => {
    const index = cartValue.findIndex(x => x.id === parseInt(currentItem.id)&&((x.size &&x.size===currentItem.size)||!x.size)&&((x.option &&x.option===currentItem.option)||!x.option));
    const newItem = cartValue[index];
    const tempAmount =  --newItem.amount;
    newItem.amount = Math.max(tempAmount, 1);
    const newValue = cartValue.filter((_, i) => i !== index);
    newValue.push(newItem);
    setCartValue([...newValue]);
  }

  const getPurchaseUnitsItems = () => {
    const items = []
	/** @type {number|string} */
    let total = 0
    // eslint-disable-next-line array-callback-return
    cartValue.map(item => {
      const preis = (mitgliederRabatFlag === 'true' && item.mitgliederRabat ? math.multiply(item.preis, rabatProItem) : item.preis).toFixed(2);
      const itemAmount = math.multiply(item.amount, preis);
      total = math.add(total, itemAmount).toFixed(2);
      const size = item.size ? item.size :'0';
      const option = item.option ? item.option :'0';
      const camper = item.amountCamper ? item.amountCamper: '0';
      const tent = item.amountTent ? item.amountTent: '0';
      items.push({
        "name": item.name,
        "sku": item.id+"#"+size +"#"+ camper+"#"+ tent +"#"+ option,
        "unit_amount": {value: preis, currency_code: 'EUR'},
        "quantity": item.amount
      })
    })
    return [{
      amount:{
        breakdown:{
          item_total:{
            currency_code:"EUR",
            value: total
          },
          shipping:{
            currency_code:"EUR",
            value: hasShipping ? math.number(shippingCost) : 0
          },
          tax_total:{
            currency_code:"EUR",
            value: math.number("0.00")
          }
        }     ,
        currency_code:"EUR",
        value: hasShipping ? math.add(total, shippingCost).toFixed(2) : math.number(total).toFixed(2)
      },
      items,
  }];
  }
  cartValue.sort((x, y) => {
    var n = math.subtract(x.order, y.order);
    if (n !== 0) {
        return n;
    }
    return ('' + x.size).localeCompare(y.size);
  });
  return (
    <div className='cartBox'>
      <div className='content'>
        <div className="CartContainer">
            <div className="CartHeader">
              <h3 className="Heading">Warenkorb</h3>
              <h5 onClick={e => deleteAll()} className="Action">alles löschen</h5>
            </div>
            {cartValue.map(item => {
            const preis = (mitgliederRabatFlag === 'true' && item.mitgliederRabat ? math.multiply(item.preis, rabatProItem) : item.preis).toFixed(2);
            const itemAmount = math.multiply(item.amount, preis);
            cartAmount = math.add(cartAmount, itemAmount);
            articleAmount = math.add(articleAmount, item.amount);
            if(abholungFlag!=='true' && item.versandkosten && item.versandkosten!==0){
              hasShipping = true;
              if(shippingCost<item.versandkosten){
                shippingCost=item.versandkosten
              }
            }
            return (
            <div key={item.id} className="Cart-Items">
                <div className="image-box">
                  <img alt={item.name+ 'image'} src={item.bilder.data[0].attributes.formats.medium.url}/>
                </div>
                <div className="about">
                  <h1 className="title"><Link  to={"/shop/artikel/"+ item.id}>{item.name}</Link></h1>
                  <h3 className="subtitle">{translateText(item.subType1)}{translateText(item.subType2)? ` | ${translateText(item.subType2)}`:''}{translateText(item.subType3)? ` | ${translateText(item.subType3)}`:''} {item.amountCamper? `Camperplätze: ${item.amountCamper} `: ''}{item.amountTent? `Zeltplätze: ${item.amountTent} `: ''}{item.size? `Größe: ${item.size.toUpperCase() === 'XXL' ? '2XL' : item.size.toUpperCase() === 'XXXL' ? '3XL' : item.size.toUpperCase() === 'XXXXL' ? '4XL': item.size.toUpperCase()} `: ''}{item.option? `Option: ${item.option} `: ''}</h3>
                </div>
                <div className="counter-prices-container">
                  <div className="counter">
                    <div onClick={e => removeAmount(item)} className="btn">-</div>
                    <div className="count">{item.amount}</div>
                    <div onClick={e => addAmount(item)} className="btn">+</div>
                  </div>
                  <div className="prices">
                    <div className="amount">{itemAmount.toFixed(2)} €</div>
                    {mitgliederRabatFlag ==='true' && item.mitgliederRabat&&<div className="rabat">inklusive 20% Mitgliedsrabatt </div>}
                    <div onClick={e => deleteItem(item)} className="remove"><u>löschen</u></div>
                  </div>
                </div>
            </div>
          )})

          }
          <hr/>
          <div className="checkout">
            <div className="total">
              {hasShipping && <>
                <div>
                  <div className="shipping">Versand</div>
                </div>
                <div className="cost-amount">{shippingCost.toFixed(2)} €</div>
              </>}
            </div>
            <div className="total">
              <div>
                <div className="Subtotal">Summe</div>
                <div className="items">{articleAmount} Artikel</div>
              </div>
              <div className="total-amount">
                {math.add(math.number(cartAmount), hasShipping ? math.number(shippingCost) : math.number(0)).toFixed(2)} €
              </div>
            </div>
            <div className='agbContainer'>
              <input type="checkbox"
                id="checkAGB"
                checked={checkAGB}
                onChange={handleChange}/>
              <label htmlFor="checkAGB">
                Hiermit bestätige ich die <a href='/agb' target="_blank">AGB</a>
              </label>
            </div>
            <div className='agbContainer'>
              <input type="checkbox"
                id="checkNewsletter"
                checked={checkNewsletter}
                onChange={handleChangeNewsletter}/>
              <label htmlFor="checkNewsletter">
               Ich möchte den Newsletter erhalten.
              </label>
            </div>
            <PayPalScriptProvider options={{ "currency": "EUR","client-id": "ATKbH5n7CNywOzBHtLPEKnFBbo57Ag-5lZxQYZ6ZsSKUkiC6krjyqpfFJxv3SE0Ni2CPRLywMB0Zp7Kb" }}>
            <PayPalButtons
                disabled={!checkAGB}
                createOrder={(data, actions) => {
                  const purchase_units = getPurchaseUnitsItems();
                  return actions.order.create({purchase_units:purchase_units});
                }}
                onError={(data, actions) => {
                  throwError()
                  }
                }
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                      const posten = []
                      // eslint-disable-next-line array-callback-return
                      details.purchase_units[0].items.map(item => {
                      const fromSKU = item.sku.split('#')
                          posten.push({
                                productId: fromSKU[0],
                                anzahlCamperPlatz: parseInt(fromSKU[2]),
                                anzahlZeltPlatz: parseInt(fromSKU[3]),
                                option: fromSKU[4]||'',
                                grosse: fromSKU[1]||'',
                                anzahl: item.quantity,
                                name: item.name,
                                preis: item.unit_amount.value,
                              })
                        })
                        const data = {}
                        data.data = {
                            bestellnummer: details.id,
                            erstellt: details.create_time,
                            shippingCost: details.purchase_units[0].amount.breakdown.shipping.value+'',
                            item_total: details.purchase_units[0].amount.breakdown.item_total.value+'',
                            summe: parseFloat(details.purchase_units[0].amount.value),
                            posten: posten,
                            ShippingAddress: details.purchase_units[0].shipping.address,
                            billing_address: details.payer.address,
                            Besteller: {
                              name: details.payer.name.surname,
                              vorname: details.payer.name.given_name,
                              email: details.payer.email_address,
                              payerId: details.payer.payer_id,
                              RIGMitglied: mitgliederRabatFlag,
                              Abholung: abholungFlag,
                              Newsletter: JSON.parse(window.sessionStorage.getItem("newsletter"))
                            },
                          };
                          data.data.ShippingAddress.admin_area_1 = details.purchase_units[0].shipping.address.address_line_2;

                        axios.post('https://strapi-3tgn.onrender.com/api/bestellungen', data)
                        .then(function () {
                          axios.post('https://www.rock-im-garten.com/mailer.php', data)
                          .then(function () {
                            deleteAll();
                            setCheckedAGB(false);
                            hasShipping =  false;
                            window.sessionStorage.removeItem("abholung");
                            window.sessionStorage.removeItem("mitglied");
                            window.sessionStorage.removeItem("newsletter");
                            Store.addNotification({
                              title: "Woopwoop",
                              message: "Deine Bestellung war erfolgreich",
                              type: "success",
                              insert: "top",
                              container: "top-center",
                              animationIn: ["animate__animated", "animate__fadeIn"],
                              animationOut: ["animate__animated", "animate__fadeOut"],
                              dismiss: {
                                duration: 10000,
                                onScreen: true
                              }
                            });
                          }).catch(function (error = 'Bestätigungsmail konnte nicht gesendet werden.Bestellung wurde aber getätigt.') {
                          throwError(JSON.stringify(error));
                        });
                        })
                        .catch(function (error) {
                          throwError(JSON.stringify(error));
                        });
                    });
                }}
            />
        </PayPalScriptProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
