import Accordion from "../../components/Accordion";
import React, { useEffect, useState} from 'react';
import axios from 'axios';
import Spinner from './../../assets/images/spinner.svg'

export default function Faq() {
  const [faqData, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () =>{
      setLoading(true);
      try {
        const {data: response} = await axios.get('https://strapi-3tgn.onrender.com/api/faqs?populate=*');
        setData(response.data);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    }
    fetchData();
  }, [setData]);

  return (
    <div className="faq">
      <h1>FAQ 2025</h1>
      {loading && <div className='loading'><img src={Spinner} alt="loading animation"/></div>}
      {!loading && <Accordion
        questionsAnswers={faqData}
        defaultSelection={0}
      />}
    </div>
  );
}
