import './sponsoren.scss';
import React, { useEffect, useState} from 'react';
import axios from 'axios';

export default function Sponsoren() {
  const [sponsorenData, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () =>{
      setLoading(true);
      try {
        const {data: response} = await axios.get('https://strapi-3tgn.onrender.com/api/sponsor?populate=deep');
        setData(response.data);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    }
    fetchData();
  }, [setData]);
  return !loading && (
    <div className='SponsorenContainer'>
      <h1>{sponsorenData.attributes.Title}</h1>
      <p className='sponsorenText' dangerouslySetInnerHTML={{ __html: sponsorenData.attributes.Text }}></p>
      {sponsorenData.attributes.Sponsor.map((SponsorData, i) => {
                return <div className={i % 2 === 0 ? 'SponsorenList': 'SponsorenList2'}>
                  <a href={SponsorData.url} target="_blank" rel="noreferrer" >
                    <img className='sponsorImage' src={SponsorData.image.data.attributes.url} alt={"SponsorenImage" + i} />
                  </a>
                  <p dangerouslySetInnerHTML={{ __html: SponsorData.text }}></p>
                  </div>
            })}
    </div>
  );
}
