import './band.scss';
import { useLocation } from 'react-router-dom'
import Facebook from './../../assets/images/facebook-logo.png'
import Insta from './../../assets/images/instagram-logo.png'
import Spotify from './../../assets/images/spotify.png'
import Youtube from './../../assets/images/youtube-logo.png'
import ReactMarkdown from 'react-markdown'

export default function Bands({bandData, path}) {
  const location = useLocation();
  const currentRAW = bandData.find(x => path + x.attributes.rigUrl === location.pathname);
  const current = currentRAW.attributes;
  const image = current?.image?.data?.attributes?.formats?.medium?.url? current?.image?.data?.attributes?.formats?.medium?.url : current.image.data.attributes.url
  return (
    <div className='bandBox'>
      <div className='content'>
        <h1 className='title'>{current.name}</h1>
        <div className='info'>
          <span className='genre'>{current.genre}</span> | <span className='home'>{current.ort}</span>
        </div>
        <div className='box'>
          <div className='bandPic'>
            {<img src={image} alt={current.name+ '- Bandimage'}/>}
          </div>
          <div className='text'>
            <div><ReactMarkdown>{current.text}</ReactMarkdown></div>
            <div className='links'>
              {current.facebookUrl && <a target="_blank" href={current.facebookUrl} title={'zum Facebook-Account von' + current.name} rel="noreferrer"><img className="icon" src={Facebook} alt="Rock im Garten Facebook Logo" /></a>}
              {current.instaUrl && <a target="_blank" href={current.instaUrl} title={'zum Instagram-Account von' + current.name} rel="noreferrer"><img className="icon" src={Insta} alt="Rock im Garten Instagram Logo" /></a>}
              {current.youtube && <a target="_blank" href={current.youtube} title={'zum Youtube-Account von' + current.name} rel="noreferrer"><img className="icon" src={Youtube} alt="Rock im Garten Youtube Logo" /></a>}
              {current.spotify && <a target="_blank" href={current.spotify} title={'zum Spotify-Account von' + current.name} rel="noreferrer"><img className="icon" src={Spotify} alt="Rock im Garten Spotify Logo" /></a>}
            </div>
            {current.url && <><br/><a href={current.url} title={'Link zu ' + current.name} className='link' target="_blank" rel="noreferrer"> -{'>'} zur Homepage von {current.name}</a></>}
          </div>
        </div>
      </div>
    </div>
  );
}