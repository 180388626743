import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import './accordion.scss';

const AccordionItem = ({
  item,
  showDescription,
  ariaExpanded,
  fontWeightBold,
  index,
  onClick,
}) => {
  const accordionBTNClasses = classNames({}, 'faq__question-button', {
    'fontWeightBold': fontWeightBold,
  });
  const accordionClasses = classNames({}, 'faq__desc', {
    'showDescription': showDescription,
  });
  const accordionImageClasses = classNames('faq__img', 'faq__desc', {
    'showDescription': showDescription,
  });
  const image = item?.image?.data?.attributes ? item?.image?.data?.attributes?.formats?.medium?.url? item?.image?.data?.attributes?.formats?.medium?.url : item.image.data.attributes.url : null;
  return (
    <div className="faq__question" key={item.linkId}>
      <span
        aria-expanded={ariaExpanded}
        aria-controls={`faq${index + 1}_desc`}
        className={accordionBTNClasses}
        onClick={onClick}
        id={item.linkId ? item.linkId : index + 1}>
        {item.question}
      </span>
      <div data-qa="faq__desc" className={accordionClasses}>
        <ReactMarkdown>{item.answer}</ReactMarkdown>
      </div>
      {image&&<img className={accordionImageClasses} src={image} alt={"Image für"+item.question}  />}
    </div>
  );
};

AccordionItem.propTypes = {
  item: PropTypes.object,
  showDescription: PropTypes.bool,
  ariaExpanded: PropTypes.bool,
  fontWeightBold: PropTypes.bool,
  index: PropTypes.number,
  onClick: PropTypes.func,
  fullUrl: PropTypes.string,
};

export default AccordionItem;
