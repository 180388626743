import './flyer.scss';
import Rig12FlyerVorderseite from '../../assets/images/RiG12-2025Flyer-2-Vorderseite.png'
import Rig12FlyerRueckseite from '../../assets/images/RiG12-2025Flyer-2-Rueckseite.png'

/**
 * @typedef
 */

/**
 * @param {import('react').HTMLProps<HTMLImageElement> & { srcHover?: string}} props
 */
export default function Flyer(props) {
  const { src, srcHover, ...imgProps } = props

  return (
    <div className="container">
      <img
        {...imgProps}
        className="flyer touch"
        src={src}
        alt="Rock im Garten 12 - Flyer" />
      { srcHover &&
        <img
          {...imgProps}
          className="flyer touch"
          src={srcHover}
          alt="Rock im Garten 12 - Flyer" />
      }
    </div>
  )
}

/**
 * @param {Omit<import('react').HTMLProps<HTMLImageElement>,"src">} props
 */
export function RiG12Flyer(props) {
  return (
    <Flyer
      src={Rig12FlyerVorderseite}
      srcHover={Rig12FlyerRueckseite}
      alt="Rock im Garten 12 - Flyer" {...props} />
  )
}