import './article.scss';
import { useLocation } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import {useState,useEffect, useContext, useRef, Fragment} from 'react';
import { ArticleContext, CartContext } from '../../components/context';
import { Store } from 'react-notifications-component';
import SoldOutIcon from './stampsold01.svg'
export const translateText = (key) => {
  const map = {
    Ticket: 'Hardticket für den 19.07.2025',
    FrontPrintGross: 'Frontprint Logo groß',
    FrontPrintKlein: 'Frontprint Logo klein',
    Backprint: 'Backprint Bands'
  }
  return map[key] || key;
}
export default function Article() {
  const {articleData} = useContext(ArticleContext);
  const [size, setSize] = useState('s');
  const [amount, setAmount] = useState(1);
  const [amountTent, setAmountTent] = useState(null);
  const [amountCamper, setAmountCamper] = useState(null);
  const { cartValue, setCartValue } = useContext(CartContext);
  const [selectedOption, setSelectedOption] = useState(0);
  const location = useLocation();
  const search = location.pathname.replace('/shop/artikel/','')
  const attributes=articleData.find(x => x.id === parseInt(search))
  const current=attributes.attributes
  current.id=attributes.id
  const [index, setIndex] = useState(0);
  const delay = 6000;
  const timeoutRef = useRef(null);
  const [sliderImages, setSliderImages] = useState(current.bilder.data);
  const options = [];
  Object.keys(current.optionen).forEach(function(key, index) {
    options.push(current.optionen[key].name)
  })
  const putIntoCart = () => {
    const currentItem = {amount, ...current};
    if((current.showTentOption && !amountTent && amountTent !== 0) || (current.showCamperOption && !amountCamper && amountCamper !== 0 )){
      Store.addNotification({
        title: "Fehler",
        message: "Bitte die Anzahl der Wohnwagen bzw. Zeltstellplätze auswählen",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
      return
    }

    if(amount>0){
    const currentCart = cartValue || [];
    if(currentCart.length===0 && current.subType1==='Support'){
      Store.addNotification({
        title: "Fehler",
        message: "Du kannst das Supporterpaket nur zusammen mit anderen Artikeln bestellen.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
      return
    }
    if(current.showCamperOption){ currentItem.amountCamper = amountCamper}
    if(current.showTentOption){ currentItem.amountTent = amountTent}
    if(current.sizes){ currentItem.size = size}
    if(current.optionen.length !== 0){
      currentItem.option = current.optionen[selectedOption].name;
      currentItem.bilder = current.optionen[selectedOption].bilder
    }
    const indexOfCurrentItemInCart = currentCart.findIndex(x => x.id === parseInt(currentItem.id)&&((x.size &&x.size===currentItem.size)||!x.size)&&((x.option &&x.option===currentItem.option)||!x.option))
    if(indexOfCurrentItemInCart<0){
      currentCart.push(currentItem)
    }else{
      if(current.subType1==='Support'){
        currentCart[indexOfCurrentItemInCart].amount = 1
      }else if(currentItem.type==='Tickets'){
        currentCart[indexOfCurrentItemInCart].amount = currentCart[indexOfCurrentItemInCart].amount + currentItem.amount;
        currentCart[indexOfCurrentItemInCart].amountCamper = currentCart[indexOfCurrentItemInCart].amountCamper + currentItem.amountCamper;
        currentCart[indexOfCurrentItemInCart].amountTent = currentCart[indexOfCurrentItemInCart].amountTent + currentItem.amountTent;
      } else{
        currentCart[indexOfCurrentItemInCart].amount = currentCart[indexOfCurrentItemInCart].amount + currentItem.amount;
      }
    }

    setCartValue([...currentCart])
    Store.addNotification({
      title: "Artikel hinzugefügt",
      message: current.name+ " liegt jetzt im Warenkorb",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    }
  }

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === current.bilder.data.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index, current.bilder.data]);

  const titleClass = current.type === 'BAK' ? 'BAK title' : 'title'
  const buttonText = current.soldOutLogo ? 'Ausverkauft!' : 'In den Warenkorb';
  const onOptionChange = (option) => {
    setSliderImages(current.optionen[option].bilder.data);
    setSelectedOption(option);
  };

  return (
    <div className='articleBox'>
      <div className='content'>
        <div className='box'>
          <div className='articlePic'>
            <div className="slideshow">
              <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
              >
                {sliderImages.map((item, index) => (
                  <div
                    className="slide"
                    key={index}
                  ><img src={item.attributes.formats.medium.url} alt={current.name+ '- Artikel Image'}/></div>
                ))}
              </div>

              <div className="slideshowDots">
                {sliderImages.map((_, idx) => (
                  <div
                    key={idx}
                    className={`${current.type} slideshowDot${index === idx ? " active" : ""}`}
                    onClick={() => {
                      setIndex(idx);
                    }}
                  ></div>
                ))}
              </div>
            </div>
            {current.soldOutLogo && <img className='soldOut' src={SoldOutIcon} alt="Sold out banner"/>}
          </div>
          <div className='text'>
            <h1 className={titleClass}>{current.name}</h1>
            <h3 className="subtitle">{translateText(current.subType1)}{translateText(current.subType2)? ` | ${translateText(current.subType2)}`:''}{translateText(current.subType3)? ` | ${translateText(current.subType3)}`:''}</h3>

            {current.optionen.length !== 0 && <>
              <span>Option wählen:&nbsp;</span> <select
                className='optionSelect'
                value={selectedOption}
                onChange={e => onOptionChange(e.target.value)}
              >
                {options.map((idx,key) => (
                <option value={key}>{idx}</option>
              ))}
              </select>
            </>}

            {current.sizes &&
              <div className={current.type+" wrapper"}>
              {
                Object.keys(current.sizes ?? {})
                  .filter(key => key !== 'id')
                  .map((key, index) => (
                    <Fragment key={key}>
                      <input
                        type="radio"
                        name="select"
                        onChange={e => setSize(e.target.value)}
                        id={"option-"+index}
                        checked={size === key.toLowerCase()}
                        value={key.toLowerCase()}
                      />
                      <label
                        htmlFor={"option-"+index}
                        className={"option option-"+index}
                      >
                        <span>
                          {{
                            'XXL': '2XL',
                            'XXXL': '3XL',
                            'XXXXL': '4XL',
                          }[key] ?? key}
                        </span>
                      </label>
                    </Fragment>
                  )
                )
              }
              </div>
            }

            {current.showCamperOption &&
              <div className='orderControl'>
                <input
                  className="amount"
                  type="number"
                  name="amountTent"
                  onChange={e => setAmountTent(Math.max(0 , e.target.value))}
                  value={amountTent} />
                kostenfreier Zeltplatz
                <br/>
              </div>
            }

            {current.showTentOption &&
              <div className='orderControl'>
                <input
                  className="amount"
                  type="number"
                  name="amountCamper"
                  onChange={e => setAmountCamper(Math.max(0 , e.target.value))}
                  value={amountCamper} />
                kostenfreier Wohnwagen / Camper Stellplatz
                <br/>
              </div>
            }

            <ReactMarkdown>{current.beschreibung}</ReactMarkdown>

            <div className="prices">
              Preis: <div className="amount">{current.preis.toFixed(2)} €</div>
            </div>

            <div className='orderControl'>
              <div className='amountLabel'><input className='amount' type="number" name="amount" onChange={e => setAmount(current.subType1==='Support'? 1 : Math.max(0 , e.target.value))} value={amount} /> </div>
              <button disabled={current.soldOutLogo} className={current.type+' button'} onClick={ e => putIntoCart()}>{buttonText}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}