import Animation from '../../assets/images/Oma-und-Punk2.gif'
import Logo from '../../assets/images/RiGlogo.png'
import Facebook from '../../assets/images/facebook-logo2.png'
import Insta from '../../assets/images/instagram-logo2.png'
import Youtube from './../../assets/images/youtube-logo2.png'
import { RiG12Flyer } from '../../components/flyer/flyer'

import './home.scss'
import RigAkustikFlyer from '../../components/flyer/RigAkustikFlyer'

export default function Home() {
    return (
      <div className="home">
        <div className="startAni">
          <img className="logo" src={Logo} alt="Rock im Garten - Logo" />
          <img className="gif" src={Animation} alt="Oma-und-Punk animation" />
          <h1 className='location'>Rohrsheim 19.07.2025</h1>
        </div>
        <div className='links'>
          <a href='https://www.facebook.com/RockimGartenev' target='_blank' title='zum Facebook-Account' rel="noreferrer"><img className="icon" src={Facebook} alt="Rock im Garten Facebook Logo" /></a>
          <a href='https://www.instagram.com/rock_im_garten' target='_blank' title='zum Instagram-Account' rel="noreferrer"><img className="icon" src={Insta} alt="Rock im Garten Instagram Logo" /></a>
          <a href='https://youtube.com/@rockimgartene.v.4649' target='_blank' title='zum Youtube-Account' rel="noreferrer"><img className="icon" src={Youtube} alt="Rock im Garten Youtube Logo" /></a>
        </div>
        <div className='flyerBox'>
          <a href='/akustik/LucasUecker'><RigAkustikFlyer className="flyer"/></a>
        </div>
        <div className='flyerBox'>
          <a href='/bands/ZSK'><RiG12Flyer className="flyer"/></a>
        </div>
      </div>
    );
  }