import React from 'react';
import FlyerAkustik from '../../assets/images/RiGAkustik25Flyer.jpg'
import FlyerAkustik720p from '../../assets/images/RiGAkustik25Flyer_720p.jpg'

/**
 * @param {React.HTMLProps<HTMLImageElement>} props
 */
export default function RigAkustikFlyer(props = {}) {
   return (
        <picture>
          <source srcSet={FlyerAkustik720p} media="(max-width: 600px)" />
          <source srcSet={FlyerAkustik} media="(min-width: 600px)" />
          <img
            alt="Rock im Garten Akustik - Flyer"
            src={FlyerAkustik}
            {...props}
            />
        </picture>
   )
}